<template>
  <page-view>
    <div class="notice_wrapper">
      <a-select
        v-model="queryParam.type"
        style="width: 240px"
        placeholder="请选择类型"
      >
        <a-select-option
          v-for="item in typeArrayAll"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button
        type="primary"
        @click="
          () => {
            $refs.table.refresh(false);
          }
        "
        class="query_btn"
        >查询</a-button
      >
      <a-button @click="handleNoticeInsert" class="add_btn">新增</a-button>

      <div class="notice_table">
        <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
          <span slot="serial" slot-scope="text, record, index">{{
            index + 1
          }}</span>

          <template slot="content" slot-scope="text, scope">
            <div v-html="text"></div>
          </template>
          <template slot="operation" slot-scope="text, scope">
            <span @click="deleteNotice(scope)" class="delete_btn">删除</span>
          </template>
        </s-table>
      </div>
      <a-modal
        title="新增公告"
        :width="560"
        :visible="showEditModal"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <a-form>
          <a-form-item
            label="标题"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
          >
            <a-input placeholder="公告标题" v-model.trim="notice.title" />
          </a-form-item>

          <a-form-item
            label="类型"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
          >
            <a-select placeholder="公告类型" v-model="notice.type">
              <a-select-option
                v-for="item in typeArray"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item
            label="内容"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 18 }, sm: { span: 24 } }"
          >
            <a-textarea
              class="notice_input"
              placeholder="公告内容"
              :autoSize="{ minRows: 8 }"
              v-model.trim="notice.content"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { PageView } from '@/layouts';
import { noticeInsert, noticeListGet, noticeDelete } from '@/self';
import { jsonToArray, noticeType, formatDate } from '@/utils/util';
import { STable } from '@/components';
export default {
  components: {
    PageView,
    STable
  },

  data() {
    return {
      showEditModal: false,
      queryParam: {
        type: 'all'
      },
      notice: {
        type: '',
        title: '',
        content: ''
      },
      typeArray: jsonToArray(noticeType),
      typeArrayAll: [],
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '公告标题',
          dataIndex: 'title'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: (text) => noticeType[text]
        },

        {
          title: '公告内容',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          width: 400
        },
        {
          title: '发布时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        let params = JSON.parse(JSON.stringify(this.queryParam));
        params.type == 'all' && delete params.type;
        return noticeListGet(Object.assign(parameter, params)).then(
          (result) => {
            console.log(result.data);
            return result.data;
          }
        );
      }
    };
  },
  mounted() {
    this.typeArrayAll = JSON.parse(JSON.stringify(this.typeArray));
    let obj = {
      value: 'all',
      name: '全部公告'
    };
    this.typeArrayAll.unshift(obj);
  },
  methods: {
    handleNoticeInsert() {
      this.notice = {};
      this.showEditModal = true;
    },
    deleteNotice(data) {
      this.$confirm({
        title: '是否删除本公告',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          //删除
          noticeDelete(data.id).then((res) => {
            if (res.success) {
              this.$message.success('删除成功');
              this.$refs.table.refresh(false);
            } else {
              this.$message.error(res.msg);
            }
          });
        },
        onCancel() {}
      });
    },
    handleCancel() {
      this.showEditModal = false;
    },
    handleOk() {
      const { notice } = this;
      notice.createTime = new Date().getTime();
      if (!notice.title) {
        return this.$message.error('请输入公告标题');
      }
      if (!notice.type) {
        return this.$message.error('请选择公告类型');
      }
      if (!notice.content) {
        return this.$message.error('请输入公告内容');
      }

      noticeInsert(notice).then((res) => {
        this.notice = {};
        if (res.success) {
          this.$message.success('新增成功');
          this.$refs.table.refresh(false);
          this.showEditModal = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.notice_wrapper {
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  .query_btn {
    margin-left: 64px;
  }
  .add_btn {
    margin-left: 12px;
  }

  .delete_btn {
    cursor: pointer;
    color: #1890ff;
  }
  .notice_table {
    margin-top: 24px;
  }
}
</style>
