var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',[_c('div',{staticClass:"notice_wrapper"},[_c('a-select',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择类型"},model:{value:(_vm.queryParam.type),callback:function ($$v) {_vm.$set(_vm.queryParam, "type", $$v)},expression:"queryParam.type"}},_vm._l((_vm.typeArrayAll),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1),_c('a-button',{staticClass:"query_btn",attrs:{"type":"primary"},on:{"click":function () {
          _vm.$refs.table.refresh(false);
        }}},[_vm._v("查询")]),_c('a-button',{staticClass:"add_btn",on:{"click":_vm.handleNoticeInsert}},[_vm._v("新增")]),_c('div',{staticClass:"notice_table"},[_c('s-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"data":_vm.dataSource},scopedSlots:_vm._u([{key:"serial",fn:function(text, record, index){return _c('span',{},[_vm._v(_vm._s(index + 1))])}},{key:"content",fn:function(text, scope){return [_c('div',{domProps:{"innerHTML":_vm._s(text)}})]}},{key:"operation",fn:function(text, scope){return [_c('span',{staticClass:"delete_btn",on:{"click":function($event){return _vm.deleteNotice(scope)}}},[_vm._v("删除")])]}}])})],1),_c('a-modal',{attrs:{"title":"新增公告","width":560,"visible":_vm.showEditModal},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form',[_c('a-form-item',{attrs:{"label":"标题","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-input',{attrs:{"placeholder":"公告标题"},model:{value:(_vm.notice.title),callback:function ($$v) {_vm.$set(_vm.notice, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notice.title"}})],1),_c('a-form-item',{attrs:{"label":"类型","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-select',{attrs:{"placeholder":"公告类型"},model:{value:(_vm.notice.type),callback:function ($$v) {_vm.$set(_vm.notice, "type", $$v)},expression:"notice.type"}},_vm._l((_vm.typeArray),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"内容","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 18 }, sm: { span: 24 } }}},[_c('a-textarea',{staticClass:"notice_input",attrs:{"placeholder":"公告内容","autoSize":{ minRows: 8 }},model:{value:(_vm.notice.content),callback:function ($$v) {_vm.$set(_vm.notice, "content", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notice.content"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }